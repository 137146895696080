.video-thumbnail {
  position: relative;
  top: 0;
  left: 0;
  z-index: 11;
  background: #fff;
  background-size: cover;
  background-position: center; // Ensure the background is centered
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;

  .thumbnail-content {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute; // Use absolute positioning
    top: 0; // Ensure the content covers the entire thumbnail
    left: 0;
    width: 100%;
    height: 100%;
  }

  .header-logo-sec {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 25px 16px 10px;
    img {
      width: 100px;
    }
  }
  .play-sec {
    position: absolute;
    top: 38%;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .thumbnail-title {
    position: absolute;
    bottom: 20px;
    padding: 1rem;
    color: #fff;
    width: 100%;
    .title {
      font-size: 36px;
      margin: 0;
      margin-top: 25px;
    }
    .brand {
      font-size: 23px;
    }
  }
  .social-icons {
    position: absolute;
    right: 0;
    background: #fff;
    height: 50px;
    border-radius: 25px;
    padding: 0 10px;
    width: 0;
    white-space-collapse: collapse;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    &.show {
      opacity: 1;
      width: 210px;
    }
    svg {
      width: 30px;
      margin-left: 10px;
      cursor: pointer;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .rounded-shadow {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 20px;
    cursor: pointer;
    position: relative;
    svg {
      fill: #fff;
    }
  }
  .sonar-wrapper {
    position: relative;
    z-index: 0;
    overflow: hidden;
    .sonar-emitter {
      position: relative;
      svg {
        position: absolute;
        top: 26px;
        left: 26px;
      }
    }
  }

  /* The circle */
  .sonar-emitter {
    position: relative;
    margin: 0 auto;
    width: 150px;
    height: 150px;
    border-radius: 9999px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  /* the 'wave', same shape and size as its parent */
  .sonar-wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 9999px;
    background-color: rgba(256, 256, 256, 0.7);
    opacity: 0;
    z-index: -1;
    pointer-events: none;
  }

  /*
		Animate!
		NOTE: add browser prefixes where needed.
	  */
  .sonar-wave {
    animation: sonarWave 2s linear infinite;
  }

  @keyframes sonarWave {
    0% {
      opacity: 0.4;
      transform: scale(0.5);
    }
    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }
  .share-sec {
    .d-j-s {
      justify-content: space-between;
    }
  }
}
