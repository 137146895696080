.form-group {
  width: 100%;
  &.description {
    label {
      font-size: 22px;
    }
    .form-control button.btn-option {
      display: block;
      text-align: left;
      width: 100%;
      position: relative;
      overflow: hidden;
      &:hover {
        background-color: transparent;
        color: #fff;
      }
      .text-layer {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        height: 100%;
        background: rgba(256, 256, 256, 0.5);
      }
    }
  }
  label {
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
    display: inline-block;
    color: #fff;
    //text-transform: capitalize;
    transition: bottom 0.3s ease-in-out;
  }
  .form-control {
    margin-bottom: 10px;
    .input-box {
      position: relative;
      width: 100%;
      z-index: 0;
      label {
        position: absolute;
        bottom: 45px;
        z-index: -1;
        &.focus {
          z-index: 0;
          bottom: 45px;
        }
      }
      svg {
        position: absolute;
        right: 15px;
        top: 12px;
      }
    }
    input {
      width: 100%;
      outline: 0;
      background-color: transparent;
      border: 2px solid #fff;
      padding: 10px;
      border-radius: 30px;
      font-size: 18px;
      color: #fff;
      padding-right: 40px;
      &::placeholder {
        color: rgba(256, 256, 256, 0.5);
      }
      &[type="date"] {
        background-image: url(../../..//public/assets/calendar.svg);
        background-repeat: no-repeat;
        background-position: right;
        background-size: 25px;
        background-position-x: 88%;
        background-position-y: 6px;
      }
      &::-webkit-inner-spin-button,
      &::-webkit-calendar-picker-indicator {
        opacity: 0;
        -webkit-appearance: none;
      }
      &.error {
        border-color: #d57777;
        color: #d57777;
      }
    }
    .error-msg {
      color: #d57777;
      margin: 5px 0 0;
      padding-left: 15px;
    }
    button {
      &.btn-option {
        text-transform: uppercase;
        background-color: transparent;
        color: #fff;
        border: 2px solid #fff;
        border-radius: 30px;
        font-size: 18px;
        padding: 10px 20px;
        transition: all 0.3s ease-in-out;
        &.selected,
        &:hover {
          background-color: #fff;
          color: #000;
        }
      }
      &.submit {
        color: #000;
        background-color: #01c7ea;
        border-color: #01c7ea;
      }
    }
    @media (max-width: 480px) {
      input {
        &[type="date"] {
          background-position-x: 86%;
          min-height: 47px;
        }
      }
      .mobilecontainer {
        text-align: center !important;
      }
      .video-player .video-controls .lower {
        position: absolute;
        bottom: 0px;
        left: 0;
        top: auto;
        width: 100%;
        padding: 0 0px;
      }
    }
  }
}

.end-reply {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .voice-input-sec {
    padding: 0;
  }
  label {
    color: #fff;
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: 600;
    a {
      color: #fff;
    }
  }
  .score {
    color: #fff;
    font-size: 25px;
    margin: 5px 0;
  }
  .download-link {
    margin-bottom: 10px;
    a {
      color: #fff;
      font-size: 24px;
      font-weight: bold;
    }
  }
}
.flex-container {
  display: flex;
  align-items: stretch;
}

.flex-container > div {
  color: white;
  text-align: center;
  font-size: 10px;
}
.voice-label {
  bottom: 120;
}

.center-aligned-item {
  display: "flex" !important;
  justify-content: "center" !important;
  align-items: "center" !important;
  margin-top: 7px;
}

.voice-btn {
  padding: 7px;
  border-radius: 5px;
}
.preview-container {
  font-size: 15px;
  background: #dfdfdf;
  width: 75px;
  border-radius: 25px;
  margin-right: 5px;
  align-items: right; /* This property will be ignored as it's not applicable to a div */
  text-align: right;
  float: right; /* Float the container to the right */
  text-align: right;
  margin-top: 5px;
}

.voice-label {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 7px;
  padding-right: 7px;
  font-weight: 600;
  color: grey;
}
.hny-option-btn-model {
  text-transform: uppercase;
  background-color: transparent;
  color: #0a0a0a;
  border: 2px solid #000000;
  border-radius: 30px;
  font-size: 18px;
  padding: 10px 20px;
  transition: all 0.3s ease-in-out;
}
